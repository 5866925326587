import React from "react"
import Layout from "../components/layout"
import { graphql} from "gatsby"
import Img from "gatsby-image"
import {HelmetDatoCms} from "gatsby-source-datocms"

const About = ({ data: { about } }) => (
    <Layout className="solid">
        <HelmetDatoCms seo={about.seoMetaTags} />
        <div className="container">
            <div className="simple-text">
                <Img fluid={about.image.fluid} />
                <h3 className="sheet__title">{about.title}</h3>
                <div dangerouslySetInnerHTML={{
                        __html: about.content,
                    }}
                />
            </div>
        </div>
    </Layout>
)

export default About

export const query = graphql`
  query AboutQuery {
    about: datoCmsAboutPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      image {
        fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      title
      content
    }
  }
`